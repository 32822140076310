<template>
    <div class="grid">
        <div class="col-12">
            <div class="card main-card">


                <Dialog header="The ludi token" v-model:visible="display" :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" :modal="true">
                    <p class="line-height-3 m-0">
                        As one of the very early users of the platform you are eligible for free ludi tokens. These tokens are based on the Stellar network.
                        The more you interact with the platform, the more tokens you will get.
                        Later you can redeem them for networking events or special events, or sell them on the open market.
                    </p>
                    <p>
                        To receive tokens, you need a Stellar wallet.
                        There are many wallets - one of the most user-friendly and popular wallets is <a href="https://lobstr.co/">Lobstr</a>.
                        After creating a Lobstr account, you can view your Stellar address
                        <a href="https://lobstr.zendesk.com/hc/en-us/articles/360000869413-Where-can-I-find-my-Stellar-wallet-address-">here</a>.
                    </p>
                    <template #footer>
                        <Button label="Dismiss" @click="close" icon="pi pi-check" class="p-button-secondary"/>
                    </template>
                </Dialog>

                <Dialog header="Update your XLM public key" v-model:visible="displayEdit" :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" :modal="true">
                    <InputText id="key" type="text" class="w-full mb-3" v-model="keyNew" placeholder="Your XLM public key" />
                    <template #footer>
                        <Button label="Save" @click="saveKey" icon="pi pi-check" class="p-button-secondary"/>
                    </template>
                </Dialog>


                <div class="xlm_token">
                    XLM public key: <b>{{ xlm_token }}</b><i v-if="xlm_token == '' || xlm_token == undefined">No key</i>
                </div>

                <Button label="Edit Key" icon="pi pi-pencil" class="p-button-rounded p-button-text" @click="openEdit"></Button>
                <Button label="Help" icon="pi pi-question" class="p-button-rounded p-button-text" @click="open" />


                <div class="flex justify-center mb-4">
                <div class="col-12 lg:col-6 xl:col-3">
                    <div class="card mb-0">
                        <div class="flex justify-content-between mb-3">
                            <div>
                                <span class="block text-500 font-medium mb-3">Ludi Tokens</span>
                                <div class="text-900 font-medium text-xl">{{ total_tokens }}</div>
                            </div>
                            <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                                <i class="pi pi-dollar text-blue-500 text-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
                </div>



                <Toast/>
                <DataTable
                    ref="dt"
                    :value="data"
                    dataKey="id"
                    :paginator="true"
                    :rows="25"
                    :filters="filters"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,50]"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    sortField="metric"
                    :sortOrder=-1
                    :loading="loading1"
                    responsiveLayout="scroll"
                >
                    <template #empty>
                        No Ludi Tokens so far.
                    </template>

                    <Column field="xlm_public_key" header="Reason" :sortable="false" >
                        <template #body="slotProps">
                            {{slotProps.data.reason}}
                        </template>
                    </Column>
                    <Column field="ludi_amount" header="Amount" :sortable="false" >
                        <template #body="slotProps">
                            {{slotProps.data.amount}}
                        </template>
                    </Column>

                    <Column field="ludi_amount" header="Accepted?" :sortable="false" >
                        <template #body="slotProps">

                            <Button v-if="slotProps.data.accepted == 1" icon="pi pi-check" class="p-button-rounded p-button-text mr-2 mb-2" />
                            <Button v-else icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text mr-2 mb-2" />

                        </template>
                    </Column>

                </DataTable>
            </div>
        </div>
    </div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import UserService from '../service/UserService';

export default {
    data() {
        return {
            xlm_token: "",
            display: false,
            displayEdit: false,
            keyNew: "",
            data: null,
            product: {},
            filters: {},
            windowWidth: window.innerWidth,
            loading1: true,
            total_tokens: 0
        }
    },
    userService: null,
    created() {
        this.userService = new UserService();
        window.addEventListener("resize", () => {
            this.windowWidth = window.innerWidth;
        });
    },
    mounted() {
        this.userService.getXLM().then(data => {
            this.loading1 = false;
            this.data = data;
            this.total_tokens = 0;
            for (var idx in data) {
                this.total_tokens += data[idx]["amount"];
            }
        });
        this.loadKey();
    },
    methods: {
        loadKey() {
            this.userService.getUser(-1).then(data => {
                console.log(data);
                this.xlm_token = data["xlm_public_key"];
            });
        },
        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },
        openEdit() {
            this.displayEdit = true;
        },
        closeEdit() {
            this.displayEdit = false;
        },
        saveKey() {
            this.displayEdit = false;
            this.userService.updateUser(
                {
                    "xlm_public_key": this.keyNew,
                }
            ).then(() => {
                this.loadKey();
            });
        },
    }
}
</script>

<style lang="scss">
@media (max-width: 450px) {
    .layout-wrapper .layout-main-container {
        padding-left: 0rem!important;
    }
    .layout-main-container {
        padding: 7rem 0rem 2rem 0rem!important;
    }
}
</style>
<style scoped lang="scss">
@media (max-width: 450px) {
    .grid {
        margin: 0px!important;
    }
    .main-card {
        padding: 0px!important;
    }
    .xlm_token {
        padding: 1rem;
    }
}
img {
    width: 32px;
    border-radius: 30px;
}
</style>
<style>
.p-avatar.p-avatar-image {
    overflow: hidden;
}
.p-avatar img {
    height: 40px!important;
    width: auto!important;
}
.p-avatar-custom {
    overflow: hidden;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    /* border: 1px solid black; */

    background-size: cover;
    background-position: center;
}
.p-avatar-custom img {
    width: 100%;
}
.textarea {
    white-space: pre-wrap;
}
.p-rating {
    min-width: 110px;
}
.xlm_token {
    overflow: scroll;
}
</style>
